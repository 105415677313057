import React from "react"
import "./News.scss"
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery'

const newsArray = [
  {id:1,
   name: 'First',
   title: 'Персональна виставка “Руйнація”',
   description: '  Коли почалася повномасштабна війна в Україні, нас охопив жах. Було враження, що ми більше ніколи не зможемо малювати. Саме тоді Міша пішов волонтерити, склавши кисті та фарби якнайдалі.  Проте за якийсь час стало зрозуміло, що не творити і не реагувати на події через мистецтво було неможливим. Живопис став нашою зброєю!',
   srcImg: 1
  },
  {id:2,
   name: 'Second',
   title: 'Персональна виставка і Іспанії “Мир у наших руках”',
   description: '  Коли почалася повномасштабна війна в Україні, нас охопив жах. Було враження, що ми більше ніколи не зможемо малювати. Саме тоді Міша пішов волонтерити, склавши кисті та фарби якнайдалі.  Проте за якийсь час стало зрозуміло, що не творити і не реагувати на події через мистецтво було неможливим. Живопис став нашою зброєю!',
   srcImg: 2
  },
]

export const News = () => {
  return (
    <>
      <section className="news">
        <h2 className="news__title">News</h2>
        <div className="news__item">
         <div className="news__wrap">
          <Gallery withCaption>
            <Item
              original={require("../../newsFiles/3.jpg")}
              width="1600"
              height="1400"
              caption={'Bla bla bla'}
            >
              {({ref, open}) => (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img 
                  onClick={open} 
                  src={require("../../newsFiles/3.jpg")}
                  className="news__img news__img--float-left"
                />
              )}
            </Item>
          </Gallery>
          <p className="news__text news__text--right">
            <h3 className="news__text-title">100-метровий мурал в Іспанії</h3>
            <p>У червні 2023 року в іспанському місті Кордоба, в рамах творчої резиденції Фундації Артдекор (Fundación Artdecor), ми реалізували стінопис під назвою“Мир у наших руках”. Метою створення цього муралу було і є привернення уваги світової спільноти до проблеми війни в Україні та спонукання Європи продовжувати підтримувати українців у тяжкий час спротиву російській агресії </p>
            <br />
            <p>На муралі зображено матір зі своїм немовлям, які символізують любов, надію та віру у світле майбутнє українців, до якого ми так прагнемо. Сотні тисяч мам із дітьми, чоловіків та жінок загинули із початком російського вторгнення та повномасштабної війни в Україні. Тож через мистецтво ми розповідаємо Світу про нас, сильних та незламних українців, які виборюють своє життя та свободу кожного дня. Цей мурал уособлює все світле та прекрасне, що є в нашій нації. </p>
            <br />
            <p>Зображення розмістили у найбільш знаковому та відвідуваному місці Кордоби, в історичному центрі міста,  стіні стародавньої Митниці XIV століття, перед Катедральним собором Непорочного Зачаття Діви Марії (Catedral de Córdoba).</p>
            <br />
            <p>Ця робота є  уособленням нашої місії, як і більшості сучасних українських художників, - реагувати на дійсність та пояснювати Світу, що тільки від нас самих залежить мирне співіснування на цій Землі, і цей мир - у наших руках. </p>
            <br />
            <p>Над роботою ми працювали 15 днів, під пекучим сонцем Іспанії та навіть, під проливними дощами (що є рідкістю для тих країв). Крім муралу, ми привезли також серію робіт на персональну виставку у відомій галереї Circulo de la Amistad. Про неї детальніше в окремому нашому дописі _____ (посилання на допис на сайті про виставку).</p>
          </p>
         </div>

        </div>

        <div className="news__item">
         <div className="news__wrap news__wrap--revert">
          <Gallery withCaption>
              <Item
                original={require("../../newsFiles/2.jpg")}
                width="1400"
                height="1611"
                caption={'Bla bla bla'}
              >
                {({ref, open}) => (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img 
                    onClick={open} 
                    src={require("../../newsFiles/2.jpg")}
                    className="news__img news__img--float-right"
                  />
                )}
              </Item>
            </Gallery>
            <p className="news__text news__text--right">
              <h3 className="news__text-title">Персональна виставка і Іспанії “Мир у наших руках”</h3>
              <p>Незадовго після повномасштабного вторгнення росіїї в Україну, Оля з дітьми виїхала із країни (наймолодшому тоді був один місяць). Проїхавши з десяток місць, насилу дісталась до Кордоби (Іспанія) і там перебувала певний час. Але бути окремо від родини ставало нестерпно, навіть під тиском ризику від лиха війни. Вона повернулась в Україну… </p>
              <br />
              <p>За деякий час, команда іспанської фундації Artdecor Foundation зв’язалася з нами, щоб запропонувати участь у мистецькій резиденції - Кордоба, місто ідей. Через кілька тижнів бюрократії, але за сприяння Міністерства Культури України, ми з дітьми поїхали до Кордоби. Наша мета була створити зв’язок між Україною та Європою. І ми зробили виставку «Мир у наших руках» в галереї Circulo de la Amistad, яка складається з картин, створених до і під час конфлікту, а всі кошти від продажу наших картин пішли на допомогу українським біженцям, медичну й тактичну допомогу ЗСУ.</p>
              <br />
              <p>В рамах нашого перебування в Іспанії, ми також створили мурал, розміром в 100 квадратних метрів одноіменною назвою “Мир у наших руках”. Більше про мурал, можна прочитати ось тут ________ посилання. </p>
              Про галерею:
              <br />
              <p>З моменту свого заснування у 1854 року, галерея Circulo de la Amistad була найвидатнішим центром культури та мистецтва в Кордові. Вона розташована на вулиці Альфонсо XIII, і займає площу 4210 м2 зберігає цінну колекцію живопису з полотнами Хуліо Ромеро де Торреса, Карлоса Анхеля Діаса Уертаса, Хосе Марії Родрігеса де лос Ріоса. Лосада, Хосе Фернандес Альварадо, Гінес Лієбана, цікава колекція сучасного мистецтва та бібліотека, оформлена скульптором Матео Інурріа.</p>
            </p>


         </div>

        </div>
        <div className="news__item">
         <div className="news__wrap">
            <Gallery withCaption>
              <Item
                original={require("../../newsFiles/1.jpg")}
                width="1400"
                height="1611"
                caption={'Bla bla bla'}
                key={1}
              >
                {({ref, open}) => (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img 
                    onClick={open} 
                    src={require("../../newsFiles/1.jpg")}
                    className="news__img news__img--float-left"
                  />
                )}
              </Item>
            </Gallery>

            <p className="news__text">
              <h3 className="news__text-title">Персональна виставка “Руйнація”</h3>
  Коли почалася повномасштабна війна в Україні, нас охопив жах. Було враження, що ми більше ніколи не зможемо малювати. Саме тоді Міша пішов волонтерити, склавши кисті та фарби якнайдалі.  Проте за якийсь час стало зрозуміло, що не творити і не реагувати на події через мистецтво було неможливим. Живопис став нашою зброєю!
            </p>
         </div>

        </div>
      </section>
    </>
  )
}