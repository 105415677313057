import { useMediaQuery } from '@react-hook/media-query';
import { useState } from "react";
import Sticky from 'react-sticky-el';
import { FilterList } from "../../components/filterList/FilterList";
import { WorksGallery } from "../../components/worksGallery/WorksGallery";

import './WorksList.scss';


export const WorksList = () => {
  const [filterName, setFilterName] = useState('painting')
  const matches = useMediaQuery('only screen and (min-width: 768px)')

  return (
    <>
      <div className="works-list">
        <div className="works-list__wrap">
          <Sticky disabled={matches}>
            <div className="works-list__filter">
              <FilterList
                filterName={filterName}
                setFilterName={setFilterName}
              />
            </div>
          </Sticky>
          <div className="works-list__main">
            <WorksGallery
              filterName={filterName}
            />
          </div>
        </div>
      </div>
    </>
  )
}