import './Footer.scss';

export const Footer =  () => {
  return(
    <footer className="footer">
      <div className="footer__wrap">
      Copyright © Korobkov Art-studio
      </div>
    </footer>
  )
}